<template>
  <v-app>
    <drawer :model="drawer" @click="onClick" />
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="show()"></v-app-bar-nav-icon>
      <v-toolbar-title>顧客管理</v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import drawer from '@/components/Drawer'

export default {
  name: 'App',
  components: {
    drawer
  },
  data: () => ({
    drawer: false
  }),
  methods: {
    show () {
      const hideList = ['home', 'invalid-admin', 'sign-out']
      const hide = hideList.includes(this.$route.name)
      if (hide) {
        this.drawer = false
      }
      return !hide
    },
    onClick (value) {
      this.drawer = value
    }
  }
}
</script>
