<template>
  <v-navigation-drawer v-model="drawer_" app>
    <v-list dense>
      <v-list-item link to="/users">
        <v-list-item-action>
          <v-icon>mdi-account-multiple-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>顧客リスト</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item link to="/sign-out">
        <v-list-item-action>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>サインアウト</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'drawer',
  props: {
    model: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    drawer_: {
      get () { return this.model },
      set (value) { this.$emit('click', value) }
    }
  }
}
</script>
