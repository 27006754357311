import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "sign-in" */ '../views/SignIn.vue')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue')
  },
  {
    path: '/invalidAdmin',
    name: 'invalid-admin',
    component: () => import(/* webpackChunkName: "invalid-admin" */ '../views/InvalidAdmin.vue')
  },
  {
    path: '/sign-out',
    name: 'sign-out',
    component: () => import(/* webpackChunkName: "sign-out" */ '../views/SignOut.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
