import { firebase } from '@/services/firebase'

const ourRegion = 'asia-northeast1'
const firebaseFunctions = firebase.app().functions(ourRegion)

export const adminGetValidAdmin = firebaseFunctions.httpsCallable('adminGetValidAdmin')
export const adminNewId = firebaseFunctions.httpsCallable('adminNewId')
export const adminGetAuthUsers = firebaseFunctions.httpsCallable('adminGetAuthUsers')
export const adminGetAdmin = firebaseFunctions.httpsCallable('adminGetAdmin')
export const adminGetUsers = firebaseFunctions.httpsCallable('adminGetUsers')
export const adminCreateUser = firebaseFunctions.httpsCallable('adminCreateUser')
export const adminUpdateUser = firebaseFunctions.httpsCallable('adminUpdateUser')
