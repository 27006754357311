import { firebase } from '@/services/firebase'
import { adminGetValidAdmin } from '@/services/functions'

export const state = () => ({
  userDisplayName: null,
  userAuthEmail: null,
  isAuthenticating: true,
  isLoggedIn: false,
  isSigningOut: false,
  uid: null,
  isAnonymous: null,
  idToken: null,
  authTime: null,
  admin: null
})

export const mutations = {
  updateAuthStatus (state, { user }) {
    const isLoggedIn = !!user
    const hasAuthEmail = !!user && !!user.email
    state.userDisplayName = isLoggedIn ? user.displayName : null
    state.uid = isLoggedIn ? user.uid : null
    state.userAuthEmail = hasAuthEmail ? user.email : null
    state.isAuthenticating = false
    state.isLoggedIn = isLoggedIn
    state.isSigningOut = false
    state.isAnonymous = user ? user.isAnonymous : null
  },
  resetState (state) {
    state.isLoggedIn = false
    state.isAuthenticating = true
    state.userAuthEmail = null
    state.isSigningOut = false
    state.userDisplayName = null
    state.isAnonymous = null
    state.claims = null
  },
  updateIsSigningOut (state, value) {
    state.isSigningOut = value
  },
  updateAdmin (state, value) {
    state.admin = value
  }
}

export const actions = {
  async login ({ commit }, user) {
    commit('updateAuthStatus', { user })
  },
  setupAuthStateHandler ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          const { data } = await adminGetValidAdmin(user.email)
          if (data) {
            dispatch('setAdmin', data)
          }
        }
        resolve(dispatch('login', user))
      })
      firebase.auth().getRedirectResult().then(result => {
        if (result.credential) {
          dispatch('login', result.user)
        }
      }).catch(err => {
        console.error('getRedirectResult error', err) // eslint-disable-line no-console
      })
    })
  },
  async signOut ({ commit, dispatch }) {
    commit('updateIsSigningOut', true)
    try {
      await firebase.auth().signOut()
      commit('resetState')
      dispatch('setupAuthStateHandler')
    } catch (err) {
      // eslint-disable-next-line
      console.error('error signing out of firebase', err)
    } finally {
      commit('updateIsSigningOut', false)
    }
  },
  setAdmin ({ commit }, data) {
    commit('updateAdmin', data)
  }
}

export const getters = {
  completedProfile (state) {
    return !!state.rawProfile && !!state.rawProfile.firstName && !!state.rawProfile.givenName && !!state.rawProfile.email
  },
  profile (state) {
    return state.rawProfile || {}
  },
  loginTypeOk (state) {
    return process.env.VUE_APP_SKIP_LOGIN === 'yes' || state.isAnonymous === false
  }
}
