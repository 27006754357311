import Vue from 'vue'
import Vuex from 'vuex'
import * as auth from './auth'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    uid: '',
    admin: null
  },
  getters: {
    isAuthenticated: state => {
      return state.uid !== ''
    }
  },
  mutations: {
    setUId (state, uid) {
      state.uid = uid
    },
    setAdmin (state, admin) {
      state.admin = admin
    }
  },
  actions: {},
  modules: {
    auth: {
      namespaced: true,
      ...auth
    }
  },
  plugins: [createPersistedState()]
})

export default store
